import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gdpr from "src/components/Gdpr";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="hu" />
          <body />
          <script innerHTML='(window.digitalData = window.digitalData || [];
    digitalData.push({
       "event": "Page Loaded",
       "page": {
            "analyticsId":"UA-91649088-100",
            "brandName":"Visine",
            "country":"hu",
            "flattened":true,
            "hostname":"www.visine.hu",
            "language":"hu",
            "siteTier":"low",
         }
    }););' />
          <script innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','globalDataLayer','GTM-PJSTS9Z');" />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="author" content="::progressive::" />
          <meta name="publisher" content="::progressive::" />
          <meta
            name="description"
            content="VISINE® szemcsepp fáradt szemre. Vény nélkül kapható hatóanyagú gyógyszer. Orvostechnikai eszközök."
          />
          <meta name="googlebot" content="index,follow" />
          <meta name="revisit-after" content="2 days" />
          <meta name="copyright" content />
          <meta name="distribution" content="Global" />
          <meta
            name="Keywords"
            content="Visine, szemcsepp, fáradt szem, gélcsepp, irritált szem, vörös szem"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <link
            rel="shortcut icon"
            href="/static/i/favicon.ico"
            type="image/x-icon"
          />

          <title>404</title>
          <link href="/static/css/error.css" type="text/css" rel="stylesheet" />
          <meta
            property="og:image"
            content="http://visine.hu/static/images/layout/facebook_share_fix.jpg"
          />
          <link
            href="/static/css/generated_css/db53bd38efbab4a839a2b41f5144f7a7.css?=1588071944"
            type="text/css"
            rel="stylesheet"
          />
        </Helmet>
        <Gdpr />
        ;
      </React.Fragment>
    );
  }
}
